<template>
    <div class="quote_wpr">
        <div class="spinner"></div>
        <div class="loader_text" v-if="showQuote">
            {{ msg[Math.floor(Math.random() * msg.length)].quote }}
        </div>
        <div class="author" v-if="showQuote">
            <div>
                <h4>{{ msg[i].author }}</h4>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'QuoteLoader',
    data() {
        return {
            msg: [
                {
                    quote: "Take your clients where they can't take themselves.",
                },
                {
                    quote: "A good coach inspires vision and confidence.",
                },
                {
                    quote: "Thrive Coaches have happy clients.",
                },
                {
                    quote: "Are you living the THRIVE LIFE coach?",
                },
                {
                    quote: "You never get better by practicing less.",
                },
                {
                    quote: "The difference between you and other coaches is passion!",
                },
                {
                    quote: "Think about your dream. Then, put your head down and go to work.",
                },
                {
                    quote: "Nothing will work unless you work, coach.",
                },
                {
                    quote: "You are getting better every day coach!",
                },
                {
                    quote: "Today do what others won't so tomorrow you will have what others can't.",
                },
                {
                    quote: "Give 100% all of the time, and things will always work out.",
                },
                {
                    quote: "Hard work beats talent when talent doesn't work hard.",
                },
                {
                    quote: "A champion isn't made of muscle - a champion is made of heart.",
                },
                {
                    quote: "The measure of who we are is what we do with what we have.",
                },
                {
                    quote: "Never stay at the same level. Always push yourself to the next.",
                },
                {
                    quote: "The more you dream, the farther you will get.",
                },
                {
                    quote: "Continuous learning is the minimum requirement for success.",
                },
                {
                    quote: "You become what you think about and focus on the most.",
                },
                {
                    quote: "There are no shortcuts to any place worth going.",
                },
                {
                    quote: "Success isn't owned, it's leased. And the rent is due every day.",
                },
                {
                    quote: "A good coach can change the game. A great coach can change a life!",
                },
                {
                    quote: "A good coach troubles the comfortable and comforts the troubled.",
                },
                {
                    quote: "A great coach lights a fire within them not under them.",
                },
                {
                    quote: "A great coach gives corrections without causing resentment.",
                },
                {
                    quote: "Take the client where they can't take themselves.",
                },
                {
                    quote: "It's not what the coach knows it's what their clients have learned.",
                },
                {
                    quote: "Today do what others won't so tomorrow you accomplish what others can't.",
                },
                {
                    quote: "If you're going through hell, keep going, coach.",
                },
                {
                    quote: "It always seems impossible until it's done.",
                },
                {
                    quote: "It does not matter how slowly you go as long as you do not stop.",
                },
                {
                    quote: "Life is 10% what happens to you and 90% how you handle it.",
                },
                {
                    quote: "Start where you are. Use what you have. Do what you can.",
                },
                {
                    quote: "If you can dream it, you can do it.",
                },
                {
                    quote: "What you do today can improve all your tomorrows.",
                },
                {
                    quote: "Quality is not an act, it is a habit.",
                },
                {
                    quote: "The secret of getting ahead is getting started.",
                },
                {
                    quote: "Always do your best. What you plant now, you will harvest later.",
                },
                {
                    quote: "If you fell down yesterday, stand up today.",
                },
                {
                    quote: "Keep your eyes on the stars coach.",
                },
                {
                    quote: "Knowing is not enough. You must apply the knowledge.",
                },
                {
                    quote: "Good is the enemy of great.",
                },
                {
                    quote: "Setting goals is the first step in turning the invisible into the visible.",
                },
                {
                    quote: "Aim for the moon. If you miss, you may hit a star.",
                },
                {
                    quote: "Well done is better than well said.",
                },
                {
                    quote: "Either you run the day or the day runs you.",
                },
                {
                    quote: "Don't watch the clock. Just keep going.",
                },
                {
                    quote: "Only you can change your life. No one can do it for you.",
                },
                {
                    quote: "Set your goals high, and don't stop till you get there.",
                },
                {
                    quote: "Problems are not stop signs, they are guidelines.",
                },
                {
                    quote: "Act as if what you do makes a difference. It does.",
                },
                {
                    quote: "Be kind whenever possible.",
                },
                {
                    quote: "You can't cross the sea by staring at the water.",
                },
                {
                    quote: "Your talent is God's gift to you. How you use it is your gift to God.",
                },
                {
                    quote: "In order to succeed, you must first believe that you can.",
                },
                {
                    quote: "You are never too old to dream a new dream.",
                },
                {
                    quote: "The future is yours to create.",
                },
                {
                    quote: "The most effective way to do it is to start.",
                },
                {
                    quote: "You will never win if you never begin.",
                },
                {
                    quote: "Opportunity does not knock, it presents itself when you knock.",
                },
                {
                    quote: "I am not afraid... I was born to do this.",
                },
                {
                    quote: "Leap and the net will appear.",
                },
                {
                    quote: "You just can't beat the person who never gives up.",
                },
                {
                    quote: "You can't build a reputation on what you are going to do.",
                },
                {
                    quote: "No bird soars too high if he soars with his own wings.",
                },
                {
                    quote: "A goal is a dream with a deadline.",
                },
                {
                    quote: "The harder the conflict, the more glorious the triumph.",
                },
                {
                    quote: "You were born to be great.",
                },
                {
                    quote: "Hitch your wagon to a star.",
                },
                {
                    quote: "Expect problems and eat them for breakfast.",
                },
                {
                    quote: "Do something wonderful, people may imitate it.",
                },
                {
                    quote: "Success breeds motivation.",
                },
                {
                    quote: "Perseverance is failing 19 times and succeeding on the 20th.",
                },
                {
                    quote: "You gotta make it happen, coach!",
                },
                {
                    quote: "Find a way, or make a way coach!",
                },
                {
                    quote: "Without hard work, nothing grows but weeds.",
                },
                {
                    quote: "Even if you fall on your face, you're still moving forward.",
                },
                {
                    quote: "Small deeds done are better than great deeds planned.",
                },
                {
                    quote: "Great coaches were bad coaches that kept trying.",
                },
                {
                    quote: "You got this, coach!",
                },
                {
                    quote: "We love you, coach!",
                },
                {
                    quote: "You were built for greatness coach!",
                },
                {
                    quote: "If you can dream it you can do it, coach!",
                },
                {
                    quote: "You were made to change lives. You can not fail.",
                },
                {
                    quote: "You are too important to the world to fail. ",
                },
                {
                    quote: "I believe in you and so does our team!",
                },
                {
                    quote: "If you are lost just breathe and think about what you want.",
                },
                {
                    quote: "Hard days make us great coaches. Remember that!",
                },
                {
                    quote: "You are a Thrive Coach! We won't let you fail!",
                },
                {
                    quote: "Have you checked out Thrive Academy, coach?",
                },
                {
                    quote: "Have you booked your Thrive Session? Do it!",
                },
                {
                    quote: "Have you tried using the help center yet?",
                },
                {
                    quote: "Have you posted your first badge, coach?",
                },
                {
                    quote: "Have you joined the Facebook group coach?",
                },
                {
                    quote: "If you have not joined Thrive Academy you are missing GOLD!",
                },
                {
                    quote: "Have you taken your Thrive Assessment™ yet, coach?",
                },
                {
                    quote: "Want us to do your website, coach? Book a call!",
                },
                {
                    quote: "Want us to set up your Thrive Coach account? Book a call!",
                },
                {
                    quote: "Need a Thrive Certified™ virtual assistant? Book a call!",
                },
                {
                    quote: "Success is the sum of small efforts, repeated day in and day out.",
                },
            ],
            i: 0,
        }
    },

    props: {
        showQuote: {
            type: Boolean,
            default: true,
        }
    }
}
</script>
